.slick-slide {
    padding: 12px;
}

.slick-dots{
    position: static;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 12px;
}

.slick-dots > li{
    width: fit-content;
    margin: 0;
    cursor: default;
}

.slick-dots>.slick-active>div{
    width: 24px;
    background-color: #fff;
    cursor: pointer;
}

.custom-slick-dot{
    cursor: pointer;
    width: 12px;
    height: 3px;
    border-radius: 24px;
    background-color: #878787;
    transition: 300ms ease;
}