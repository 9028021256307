.ef1 {
    animation: ef1 2s infinite alternate linear;
}

.ef2 {
    animation: ef2 2s infinite alternate linear;
    transform-origin: 87% 90%;
}

.ef3 {
    animation: ef3 2s infinite alternate linear;
    transform-origin: 87% 90%;
}

.ef4 {
    animation: ef4 2s infinite alternate linear;
    transform-origin: 20% 70%;
}

.ef5 {
    animation: ef5 2s infinite alternate linear;
    transform-origin: 185% 55%;
}

.ef6 {
    animation: ef6 2s infinite alternate linear;
    transform-origin: -1% -49%;
}

@keyframes ef1 {
    0% {
        transform: rotate(20deg) translate(-39px, 25px);
        transform-origin: 25% 67%;
    }

    100% {}
}

@keyframes ef2 {
    0% {
        transform: rotate(-20deg);
    }

    100% {}
}

@keyframes ef3 {
    0% {
        transform: rotate(-20deg) translate(22%, 4%);
    }

    100% {}
}

@keyframes ef4 {
    0% {
        transform: rotate(-20deg) translate(22%, 4%);
    }

    100% {}
}

@keyframes ef5 {
    0% {
        transform: rotate(10deg) translate(24%, 3%);
    }

    100% {}
}

@keyframes ef6 {
    0% {
        transform: rotate(-20deg) translate(0%, 0%);
    }

    100% {}
}