.input-group{

    &> input{
        display: none;

        &:checked + .hamburger{
            &>div:first-child{
                transform: translateY(7px) rotate(45deg);
            }

            &>:nth-child(2){
                opacity: 0;
            }

            &>div:last-child{
                transform: translateY(-7px) rotate(-45deg);
            }
        }
    }

    .hamburger{
        width: 20px;
        height: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 0;

        &> div{
            width: 100%;
            height: 2px;
            border-radius: 8px;
            background-color: #fff;
            transition: all 0.4s ease-in-out;
        }
    }
}

