.images {
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

    }
    img:first-child {
        filter: blur(29px);
    }
}